/* eslint-disable camelcase */
import {useEffect, useState, useMemo, useCallback} from 'react';
import {PlusIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import {toast} from 'react-toastify';
import debounce from 'lodash/debounce';

import {useIntl} from 'react-intl';
import {CustomersHTTP} from '../../providers/customers';
import {useAuth} from '../../../auth/hooks/useAuth';

import PageHeader from '../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../components/SectionWrapper';
import {FormatDate} from '../../../../components/FormatDate';
import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import Search from '../../../../components/Search';
import Table from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import Modal from '../../../../components/_modals/Modal';
import {LoadingBox} from '../../../../components/LoadingBox';

import SkillsForm from './components/Form';

const Customers = () => {
  const {formatMessage} = useIntl();
  const {currentCompany} = useAuth();
  const [formType, setFormType] = useState({
    loading: false,
    type: 'create',
    selected: {},
    modal: false,
  });
  const [customers, setCustomers] = useState({
    loading: true,
    data: [],
    meta: {},
  });
  const [globalModal, setGlobalModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  function handleEdit(data) {
    if (data.global) {
      setGlobalModal(true);
      return;
    }

    setFormType((prev) => ({
      ...prev,
      type: 'edit',
      selected: data,
      modal: true,
    }));
  }

  async function fetchCustomers() {
    setCustomers({
      loading: true,
      data: [],
      meta: {},
    });
    const {data} = await CustomersHTTP.get({
      page: currentPage,
      ...(search ? {name: search} : {}),
    });
    setCustomers({
      loading: false,
      data: data.data,
      meta: data.meta,
    });
  }

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setFormType((prev) => ({...prev, loading: true}));
        const payload = {
          name: values.name,
          status: values.status,
          global: false,
        };

        if (formType.type === 'create') {
          if (currentCompany) {
            payload.company_id = currentCompany;
          }
          await CustomersHTTP.create(payload);
          toast.success('Skill created successfully!');
        } else {
          await CustomersHTTP.update(formType.selected._id, payload);
          toast.success('Skill edited successfully!');
        }
        fetchCustomers();
      } finally {
        setFormType({
          loading: false,
          type: 'create',
          selected: {},
          modal: false,
        });
      }
    },
    [formType, currentCompany],
  );

  useEffect(() => {
    fetchCustomers();
  }, [search]);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
      },
      {
        Header: `${formatMessage({id: 'phone'})}`,
        accessor: 'phone',
      },
      {
        Header: `${formatMessage({id: 'updated-at'})}`,
        accessor: ({updatedAt}) => <FormatDate value={updatedAt} />,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({row}) => (
          <IconButton
            icon={ChevronRightIcon}
            onClick={() => handleEdit(row.original)}
          />
        ),
      },
    ],
    [formatMessage],
  );

  const searchDebounce = debounce(setSearch, 500);

  return (
    <>
      <PageHeader
        title="Skills"
        breadcrumbs={[
          {
            id: 'company',
            label: formatMessage({id: 'menu.company'}),
          },
          {
            id: 'skills',
            label: formatMessage({id: 'menu.skills'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() => setFormType((prev) => ({...prev, modal: true}))}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: 'company.new-skill-button'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={customers.loading}
        toolbar={<Search onChange={(e) => searchDebounce(e.target.value)} />}>
        <Table columns={columns} data={customers.data} />

        <Pagination
          totalPages={customers.meta.lastPage}
          currentPage={currentPage}
          totalRecords={customers.meta.total}
          onChangePage={(page) => setCurrentPage(page)}
        />
      </SectionWrapper>

      <Modal
        maxWidth="max-w-2xl"
        open={formType.modal}
        onClose={() =>
          setFormType((prev) => ({...prev, modal: false, selected: {}}))
        }
        title={formatMessage({id: 'menu.skills'})}>
        <SkillsForm
          loading={formType.loading}
          initialData={formType.selected}
          onSubmit={(value) => handleSubmit(value)}
          onCancel={() =>
            setFormType((prev) => ({...prev, modal: false, selected: {}}))
          }
        />
      </Modal>

      <Modal
        maxWidth="max-w-2xl"
        open={globalModal}
        onClose={() => setGlobalModal(false)}>
        <p className="bg-yellow-50 p-4 text-yellow-800 text-lg font-bold">
          {formatMessage({id: 'company.alert-1'})}
        </p>
        <p className="p-4 text-gray-500 font-medium">
          {formatMessage({id: 'company.alert-2'})}
        </p>
      </Modal>

      {formType.loading && <LoadingBox info="Saving skill..." />}
    </>
  );
};

export default Customers;
