/* eslint-disable no-unused-vars */
import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {ChevronLeftIcon} from '@heroicons/react/24/outline';

import api from '../../../../services/api';

import Input from '../../../../components/_form/Input';
import MaskedInput from '../../../../components/_form/MaskedInput';
import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import {LoadingBox} from '../../../../components/LoadingBox';
import {validateWhiteSpaces, validateUppercase} from '../../../../utils/utils';

import logo from '../../../../assets/images/logos-inline-color.png';

const Register = ({plan, onBack}) => {
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({});

  const [loading, setLoading] = useState(false);

  const canUseEmail = async (email) => {
    try {
      if (email.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'full_email',
        value: email,
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const canUseUsername = async (value) => {
    try {
      if (value.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'username',
        value,
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        ...data,
        status: 'inactive',
        role: 'customer',
        plan_id:
          plan === 'monthly'
            ? '66a41ff402ca1c58878c4910'
            : '66a4200002ca1c58878c4911',
        plan_stripe_id:
          plan === 'monthly'
            ? 'price_1PguMIEIaUkKVAbgrXaH7t4u'
            : 'price_1PguN6EIaUkKVAbgMcrDnS22',
        sale_id: '66a42cd2ca80d0bd742f1f9e',
        trial: false,
        language: 'en',
        promotrional_flow: true,
      };
      const company = await api.post('/subscription', payload);
      navigate(`/payment?token=${company.data._id}`);
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.errors
      ) {
        const errorsKeys = Object.keys(error.response.data.err.errors);
        errorsKeys.forEach((k) => {
          const errorObj = error.response.data.err.errors[k];
          const field = errorObj.path;
          const message = errorObj.message.replace('Path', 'Field');
          setError(field, {
            type: 'manual',
            message,
          });
        });
      }

      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.data &&
        error.response.data.err.data.param
      ) {
        let field = error.response.data.err.data.param;

        if (
          error.response.data.err.data.param === 'exp_year' ||
          error.response.data.err.dataparam === 'exp_month'
        ) {
          field = 'expiry';
        }
        if (error.response.data.err.data.param === 'number') {
          field = 'card_number';
        }

        setError(field, {
          type: 'manual',
          message: error.response.data.err.data.msg || 'invalid data',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative bg-white w-full border-t-4 border-yellow-500 mb-16 pb-6">
        <IconButton
          icon={ChevronLeftIcon}
          onClick={() => onBack()}
          className="ml-6 mt-6"
        />
        <div className="flex flex-col items-center justify-center w-full h-full p-4 md:p-8">
          <img
            className="mx-auto h-12 w-auto mt-4 mb-4"
            src={logo}
            alt="WorkPodium - Logo"
          />

          <form
            className="w-full max-w-xl px-4 md:px-10 mt-6"
            onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Full name *"
                id="name"
                error={errors.name}
                errorMessage={errors.name?.message}
                autoFocus
                {...register('name', {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z\s]+$/i,
                    message: 'Provide a valid name',
                  },
                })}
              />

              <Input
                label="Company name *"
                id="company_name"
                error={errors.company_name}
                errorMessage={errors.company_name?.message}
                {...register('company_name', {required: true})}
              />
            </div>

            <div className="mt-2 grid gap-4 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Email *"
                id="email"
                error={errors.email}
                errorMessage={errors.email && errors.email.message}
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Provide a valid email',
                  },
                  validate: {
                    checkUrl: async (value) =>
                      (await canUseEmail(value)) ||
                      'This email is already in use',
                  },
                })}
              />

              <Controller
                rules={{
                  required: true,
                  minLength: 14,
                  pattern: {
                    value: /^[^_]*$/i,
                    message: 'Provide a valid phone number',
                  },
                }}
                name="phone"
                control={control}
                render={({field}) => (
                  <MaskedInput
                    {...field}
                    label="Phone *"
                    mask="(999) 999-9999"
                    maskPlaceholder="(999) 999-9999"
                    alwaysShowMask={false}
                    removeMask={false}
                    error={errors.phone}
                    errorMessage={errors.phone ? errors.phone.message : ''}
                  />
                )}
              />
            </div>

            <div className="mt-4 grid gap-4 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Username *"
                id="username"
                error={errors.username}
                errorMessage={errors.username && errors.username.message}
                {...register('username', {
                  required: true,
                  minLength: {
                    value: 4,
                    message: 'needs to be at least 4 characters',
                  },
                  validate: {
                    checkUrl: async (value) =>
                      (await canUseUsername(value)) ||
                      'This username is already in use',
                    whitespace: (val) =>
                      validateWhiteSpaces(val) || 'Remove all white spaces!',
                    uppercaseChar: (val) =>
                      validateUppercase(val) || 'Remove all uppercase letters!',
                  },
                })}
              />

              <Input
                label="Password *"
                id="password"
                type="password"
                error={errors.password}
                errorMessage={errors.password && errors.password.message}
                {...register('password', {
                  required: true,
                  minLength: {
                    value: 4,
                    message: 'needs to be at least 4 characters',
                  },
                  validate: {
                    whitespace: (val) =>
                      validateWhiteSpaces(val) || 'Remove all white spaces!',
                  },
                })}
              />
            </div>

            <div className="mt-6">
              <Button
                loading={loading}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                Start now!
              </Button>
            </div>
          </form>
        </div>
      </div>

      {loading && <LoadingBox info="Creating your account..." />}
    </>
  );
};

export default Register;
