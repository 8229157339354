import Home from '../pages/Home';

export default [
  {
    path: '/onboarding',
    element: Home,
    exact: false,
    isPrivate: true,
  },
];
