import React from 'react';
import {Routes as DefaultRoutes, Route} from 'react-router-dom';

import CustomRoute from './Route';

import authRoutes from '../modules/auth/routes';
import companyRoutes from '../modules/company/routes';
import adminRoutes from '../modules/admin/routes';
import onboardingRoutes from '../modules/onboarding/routes';
import reactiveRoutes from '../modules/reactivation/routes';

import NotFound from '../components/_layouts/NotFound';

const protectedRoutes = [
  ...onboardingRoutes,
  ...reactiveRoutes,
  ...companyRoutes,
  ...adminRoutes,
];

export default function Routes() {
  return (
    <DefaultRoutes>
      {authRoutes.map(({path, element: Component, ...rest}) => (
        <Route
          key={path}
          path={path}
          element={
            <CustomRoute isPrivate={false}>
              <Component />
            </CustomRoute>
          }
          {...rest}
        />
      ))}

      {protectedRoutes.map(({path, element: Component, isPrivate, ...rest}) => (
        <Route
          key={path}
          path={path}
          element={
            <CustomRoute isPrivate={isPrivate}>
              <Component />
            </CustomRoute>
          }
          {...rest}
        />
      ))}

      <Route path="*" element={NotFound} />
    </DefaultRoutes>
  );
}
